// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-blog-detail-js": () => import("./../../../src/templates/blog-detail.js" /* webpackChunkName: "component---src-templates-blog-detail-js" */),
  "component---src-templates-blog-landing-js": () => import("./../../../src/templates/blog-landing.js" /* webpackChunkName: "component---src-templates-blog-landing-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-press-detail-js": () => import("./../../../src/templates/press-detail.js" /* webpackChunkName: "component---src-templates-press-detail-js" */)
}

